import React, { useEffect, useRef } from "react";
import "./Farm.css";

import farmBgEarth from "../../assets/earth-farm/farm-earth-back.png";
import farmBgMoon from "../../assets/moon-farm/farm-moon-back.png";

const backgrounds = {
  earth: farmBgEarth,
  moon: farmBgMoon,
};
export const Farm = (props) => {
  const plantingsRef = useRef();

  useEffect(() => {
    const selectedBackground =
      backgrounds[props.selectedPlanet.name] || props.defaultBg;
    if (props.setBackground) {
      props.setBackground(selectedBackground);
    }
    return () => {
      if (props.setBackground) {
        props.setBackground(props.defaultBg);
      }
    };
  }, [props.selectedPlanet, props]);

  const generateGardenBeds = () => {
    const filteredPlantings = props.plantings
      .filter((planting) => planting.planet_id === props.selectedPlanet.id)
      .slice(0, 3);

    return filteredPlantings.map((planting, i) => {
      if (!planting) return null;

      let currentTime = new Date();
      const timeZoneOffset = currentTime.getTimezoneOffset() * 60000;
      currentTime = new Date(currentTime.getTime() + timeZoneOffset);
      currentTime = currentTime.getTime();
      const timeSeed = new Date(planting.time_seed).getTime();
      const timeHarvest = new Date(planting.harvest_time).getTime();
      const midPoint = timeSeed + (timeHarvest - timeSeed) / 2;

      let growStage;
      if (currentTime >= timeSeed && currentTime <= midPoint) {
        growStage = 1;
      } else if (currentTime > midPoint && currentTime <= timeHarvest) {
        growStage = 2;
      } else if (currentTime > timeHarvest) {
        growStage = 3;
      } else {
        growStage = 1;
      }

      return (
        <div
          className={`planting planting-${i + 1}`}
          key={i}
          onClick={() => props.openModal("plantingInfo", planting)}
        >
          {!planting.plant_id ? (
            <img
              src={require(`../../assets/${props.selectedPlanet.name}-farm/open-garden.png`)}
              alt="Active Ground"
            />
          ) : (
            <img
              src={require(`../../assets/${
                props.selectedPlanet.name
              }-farm/stage/${props.lang.en_name_by_id(
                planting.plant_id
              )}_${growStage}.png`)}
              alt="Garden Bed"
            />
          )}
        </div>
      );
    });
  };

  return (
    <div className="islands-container" ref={plantingsRef}>
      {generateGardenBeds()}
      {props.plantings.filter(
        (planting) => planting.planet_id === props.selectedPlanet.id
      ).length < 3 && (
        <div
          className={`planting ${
            props.plantings.filter(
              (planting) => planting.planet_id === props.selectedPlanet.id
            ).length === 0
              ? "planting-1"
              : props.plantings.filter(
                  (planting) => planting.planet_id === props.selectedPlanet.id
                ).length === 1
              ? "planting-2"
              : "planting-3"
          }`}
          onClick={() => props.openModal("plantingBuild")}
        >
          <img
            src={require(`../../assets/${props.selectedPlanet.name}-farm/lock-garden.png`)}
            alt="Garden Bed"
          />
        </div>
      )}
    </div>
  );
};

// import React, { useEffect, useRef } from "react";
// import "./Farm.css";

// import farmBgEarth from "../../assets/earth-farm/farm-earth-back.png";
// import farmBgMoon from "../../assets/moon-farm/farm-moon-back.png";
// import lockPlantationMoon from "../../assets/moon-farm/lock-garden.png"; // Убедитесь, что этот импорт используется, если он нужен

// const backgrounds = {
//   earth: farmBgEarth,
//   moon: farmBgMoon,
// };

// export const Farm = (props) => {
//   const plantingsRef = useRef();

//   useEffect(() => {
//     const selectedBackground =
//       backgrounds[props.selectedPlanet.name] || props.defaultBg;
//     if (props.setBackground) {
//       props.setBackground(selectedBackground);
//     }
//     return () => {
//       if (props.setBackground) {
//         props.setBackground(props.defaultBg);
//       }
//     };
//   }, [props.selectedPlanet, props]);

//   const generateGardenBeds = () => {
//     let startIndex, endIndex;
//     if (props.selectedPlanet.name === "moon") {
//       startIndex = 3;
//       endIndex = 6;
//     } else {
//       startIndex = 0;
//       endIndex = 3;
//     }

//     const filteredPlantings = props.plantings.filter(
//       (planting) => planting.planet_id === 1
//     );

//     return filteredPlantings.map((planting, i) => {
//       let currentTime = new Date();
//       const timeZoneOffset = currentTime.getTimezoneOffset() * 60000;
//       currentTime = new Date(currentTime.getTime() + timeZoneOffset);
//       currentTime = currentTime.getTime();
//       const timeSeed = new Date(planting.time_seed).getTime();
//       const timeHarvest = new Date(planting.harvest_time).getTime();
//       const midPoint = timeSeed + (timeHarvest - timeSeed) / 2;

//       let growStage;
//       if (currentTime >= timeSeed && currentTime <= midPoint) {
//         growStage = 1;
//       } else if (currentTime > midPoint && currentTime <= timeHarvest) {
//         growStage = 2;
//       } else if (currentTime > timeHarvest) {
//         growStage = 3;
//       }

//       return (
//         <div
//           className={`planting planting-${i + 1}`}
//           key={i}
//           onClick={() => props.openModal("plantingInfo", planting)}
//         >
//           {!planting.plant_id ? (
//             <img
//               src={require(`../../assets/${props.selectedPlanet.name}-farm/open-garden.png`)}
//               alt="Active Ground"
//             />
//           ) : (
//             <img
//               src={require(`../../assets/${
//                 props.selectedPlanet.name
//               }-farm/stage/${props.lang.en_name_by_id(
//                 planting.plant_id
//               )}_${growStage}.png`)}
//               alt="Garden Bed"
//             />
//           )}
//         </div>
//       );
//     });
//   };

//   return (
//     <div className="islands-container" ref={plantingsRef}>
//       {generateGardenBeds()}
//       <div
//         className="planting"
//         onClick={() => props.openModal("plantingBuild")}
//       >
//         <div
//           className="planting-img-container"
//           style={{
//             backgroundImage: `url(${require(`../../assets/${props.selectedPlanet.name}-farm/open-garden.png`)})`,
//           }}
//         ></div>
//       </div>
//     </div>
//   );
// };

/////////////////////////

/* <div className="plantings" ref={plantingsRef}>
  {props.plantings.length > 0 &&
    generateGardenBeds().reduce((rows, bed, index) => {
      const rowIndex = Math.floor(index / bedsPerRow);
      if (!rows[rowIndex]) {
        rows[rowIndex] = [];
      }
      rows[rowIndex].push(bed);
      return rows;
    }, [])} */

//    <div
//     className="garden-bed"
//     onClick={() => props.openModal("plantingBuild")}
//   >
//     <img src="../../assets" alt="Garden Bed" className="img-overlay" />
//     <div className="garden-bed-overlay">
//       <img className="garden-bed-hammer" src="./hammer.png" alt="Hammer" />
//     </div>
//   </div>
// </div>;

// import React, { useEffect, useRef } from "react";
// import "./Farm.css";

// import farmBgEarth from "../../assets/earth-farm/farm-earth-back.png";
// import farmBgMoon from "../../assets/moon-farm/farm-moon-back.png";

// import lockPlantationMoon from "../../assets/moon-farm/lock-garden.png";

// const backgrounds = {
//   earth: farmBgEarth,
//   moon: farmBgMoon,
// };

// const getPlantingBackground = (planetId) => {
//   if (planetId === "moon") {
//     return require(`../../assets/moon-farm/open-garden.png`);
//   }
//   return require(`../../assets/earth-farm/active-ground.png`);
// };

// export const Farm = (props) => {
//   const plantingsRef = useRef();

//   useEffect(() => {
//     props.plantings.map((planting) => console.log(planting));
//     const selectedBackground =
//       backgrounds[props.selectedPlanet.id] || props.defaultBg;
//     if (props.setBackground) {
//       props.setBackground(selectedBackground);
//     }
//     return () => {
//       if (props.setBackground) {
//         props.setBackground(props.defaultBg);
//       }
//     };
//   }, [props.selectedPlanet, props]);

//   return (
//     <div className="plantings" ref={plantingsRef}>
//       {props.selectedPlanet.id === "earth" ? (
//         <>
//           {props.plantings.slice(0, 3).map((planting) => (
//             <div className="planting" key={planting.id}>
//               <div
//                 className="planting-img-container"
//                 style={{
//                   backgroundImage: `url(${getPlantingBackground(
//                     props.selectedPlanet.id
//                     // planting.stage
//                   )})`,
//                 }}
//                 onClick={() => props.openModal("plantingInfo", planting)}
//               >
//                 {planting.plant_id ? (
//                   <img
//                     src={require(`../../assets/${props.lang.en_name_by_id(
//                       planting.plant_id
//                     )}.png`)}
//                     className="planting-img-content"
//                     alt={planting.plant_id}
//                   />
//                 ) : null}
//               </div>
//             </div>
//           ))}
//         </>
//       ) : (
//         <>
//           {props.plantings.slice(3, 6).map((planting) => (
//             <div className="planting" key={planting.id}>
//               <div
//                 className="planting-img-container"
//                 style={{
//                   backgroundImage: `url(${getPlantingBackground(
//                     props.selectedPlanet.id,
//                     planting.stage
//                   )})`,
//                 }}
//                 onClick={() => props.openModal("plantingInfo", planting)}
//               >
//                 {planting.plant_id ? (
//                   <img
//                     src={require(`../../assets/${props.lang.en_name_by_id(
//                       planting.plant_id
//                     )}.png`)}
//                     className="planting-img-content"
//                     alt={planting.plant_id}
//                   />
//                 ) : null}
//               </div>
//             </div>
//           ))}
//           <div
//             className="planting"
//             onClick={() => props.openModal("plantingBuild")}
//           >
//             <div
//               className="planting-img-container"
//               style={{
//                 backgroundImage: `url(${lockPlantationMoon})`,
//               }}
//             ></div>
//           </div>
//         </>
//       )}
//     </div>
//   );
// };
