import React from "react";
import "./BottomButton.css";

export const BottomButton = (props) => {
  return (
    <div
      className={
        props.page === props.name
          ? "bottom-button bottom-button-active"
          : "bottom-button"
      }
      onClick={
        props.lock === 1
          ? () => {
              props.openModal("coming");
            }
          : () => props.setPage(props.name)
      }
    >
      <img
        src={props.imgSrc}
        alt={props.name}
        className="bottom-button-image"
      />
    </div>
  );
};
