import React, { useState, useEffect } from "react";
import "./ComingSoonContent.css";
import {
  energy_limit,
  channel_link,
  invite_link,
  earn_for_task,
} from "../../config";
import { checkSubscribe, InviteGet } from "../../api";

const ComingSoonContent = ({
  lang,
  user,
  fetchUserData,
  tg,
  setIsOpen,
  setPage,
}) => {
  const [energy, setEnergy] = useState(user.energy);

  return (
    <div className="modal-soon">
      <h2>{lang.coming_soon()}</h2>
    </div>
  );
};
export default ComingSoonContent;
