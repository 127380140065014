// import React, { useState, useEffect } from "react";
// import { buySeed, unlockSeedApi } from "./../../api";
// import "./Shop.css";

// export const Shop = (props) => {
//   const [totalPrice, setTotalPrice] = useState(0);
//   const [isInsufficientFunds, setIsInsufficientFunds] = useState(true);
//   const [buyDisabled, setBuyDisabled] = useState(true);

//   const [items, setItems] = useState(
//     props.plants.map((plant) => ({
//       ...plant,
//       quantity: 0,
//     }))
//   );

//   useEffect(() => {
//     calculateTotalCost();
//   }, [items]);

//   useEffect(() => {
//     setBuyDisabled(isInsufficientFunds || totalPrice === 0);
//   }, [totalPrice, isInsufficientFunds]);

//   useEffect(() => {
//     setItems(
//       props.plants.map((plant) => {
//         const seed = props.seeds.find((seed) => seed.plant_id === plant.id);
//         return {
//           ...plant,
//           quantity: 0,
//           is_lock: seed ? seed.is_lock : false,
//           cost_unlock: seed ? seed.cost_unlock : 0,
//         };
//       })
//     );
//   }, [props.plants, props.seeds]);

//   const handleQuantityChange = (index, newQuantity) => {
//     setItems((prevItems) => {
//       const updatedItems = [...prevItems];
//       updatedItems[index].quantity = newQuantity;
//       return updatedItems;
//     });
//   };

//   const unlockSeed = async (plant_id) => {
//     try {
//       const data = await unlockSeedApi(props.user_id, plant_id);
//       props.setUser(data.user);
//       props.setSeeds(data.seeds);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   // const handleBuy = async () => {
//   //   if (totalPrice > props.balance) return;

//   //   try {
//   //     for (const item of items) {
//   //       if (item.quantity !== 0) {
//   //         const data = await buySeed(
//   //           props.user_id,
//   //           item.id,
//   //           "+",
//   //           item.quantity
//   //         );
//   //         props.setUser(data.user);
//   //         props.setSeeds(data.seeds);
//   //       }
//   //     }
//   //   } catch (error) {
//   //     console.error(error);
//   //   }

//   //   setItems((prevItems) =>
//   //     prevItems.map((item) => ({ ...item, quantity: 0 }))
//   //   );
//   // };

//   const handleBuy = async (item) => {
//     if (item.price > props.balance) {
//       console.error("Insufficient funds");
//       return;
//     }

//     try {
//       const data = await buySeed(props.user_id, item.id, "+", 1);
//       props.setUser(data.user);
//       props.setSeeds(data.seeds);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const calculateTotalCost = () => {
//     const totalCost = items.reduce(
//       (acc, item) => acc + item.price * item.quantity,
//       0
//     );
//     setTotalPrice(totalCost);
//     setIsInsufficientFunds(totalCost > props.balance);
//   };

//   const countPurchasedSeeds = (seedId) => {
//     const seed = props.seeds.find((s) => s.plant_id === seedId);
//     return seed ? seed.quantity : 0;
//   };

//   console.log(props.user.planet);

//   return (
//     <>
//       <ul className="content-page">
//         <li className="planet-content-item">
//           <div className="content-item-texts-info">
//             {items.map((item, index) => (
//               <span>
//                 <p style={{ fontFamily: "RaceSport" }}>
//                   {countPurchasedSeeds(item.id)}
//                 </p>
//               </span>
//             ))}
//           </div>
//         </li>

//         {items.map((item, index) => (
//           <li key={index} className="content-item">
//             {/* <p className="content-item-texts-name"> */}
//             <img
//               src={require(`../../assets/seeds/${props.lang.en_name_by_id(
//                 item.id
//               )}.png`)}
//               alt="Seed"
//               className="content-item-img"
//             />
//             {/* </p> */}
//             <div className="content-item-texts-info">
//               <span>
//                 {/* <img src="./pay.svg" className="ico-in-text" alt="pay" /> */}
//                 <p
//                   style={{
//                     fontFamily: "RaceSport",
//                     fontSize: 20,
//                   }}
//                 >
//                   {item.price}
//                 </p>
//               </span>
//             </div>
//             <button
//               className="shop-button"
//               onClick={() => handleBuy(item)}
//               disabled={item.price > props.balance}
//             />
//           </li>
//         ))}
//       </ul>
//     </>
//   );
// };import React, { useState, useEffect } from "react";

import "./Shop.css";
import { buySeed, unlockSeedApi } from "./../../api";
import React, { useState, useEffect } from "react";

export const Shop = (props) => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const newGroups = [];
    let step;

    // Определение шага в зависимости от уровня доступа пользователя
    switch (props.user.planet) {
      case 1: // Если доступна только Земля
        step = 3; // Пример, предполагается, что первые 3 элемента - это Земля
        break;
      case 2: // Если доступна Земля и Луна
        step = 6; // Первые 3 для Земли, следующие 3 для Луны
        break;
      default:
        step = props.plants.length; // Если открыты все планеты
    }

    for (let i = 0; i < props.plants.length; i += step) {
      newGroups.push(props.plants.slice(i, i + step));
    }

    setGroups(newGroups);
  }, [props.plants, props.user.planet]);

  const handleBuy = async (item, isLocked) => {
    if (isLocked || item.price > props.balance) {
      console.error("Insufficient funds");
      return;
    }

    try {
      const data = await buySeed(props.user_id, item.id, "+", 1);
      props.setUser(data.user);
      props.setSeeds(data.seeds);
    } catch (error) {
      console.error(error);
    }
  };

  const isItemLocked = (groupIndex) => {
    // Заблокировать все элементы для "moon" если уровень пользователя равен 1
    return props.user.planet === 1 && groupIndex > 0;
  };

  const countPurchasedSeeds = (seedId) => {
    const seed = props.seeds.find((s) => s.plant_id === seedId);
    return seed ? seed.quantity : 0;
  };

  return (
    <div className="shop-container">
      {groups.map((group, groupIndex) => (
        <ul key={groupIndex} className="content-page">
          <li
            className={
              groupIndex === 0
                ? "planet-content-item"
                : "planet-content-item-moon"
            }
          >
            <div className="content-item-texts-info">
              {group.map((item, index) => (
                <span key={index}>
                  <p style={{ fontFamily: "RaceSport" }}>
                    {countPurchasedSeeds(item.id)}
                  </p>
                </span>
              ))}
            </div>
          </li>
          {group.map((item, index) => (
            <li key={index} className="content-item">
              <img
                src={require(`../../assets/seeds/${props.lang.en_name_by_id(
                  item.id
                )}.png`)}
                alt="Seed"
                className="content-item-img"
              />
              <div className="content-item-texts-info">
                <span>
                  <p style={{ fontFamily: "RaceSport", fontSize: 20 }}>
                    {props.user.planet === 1 && groupIndex === 1
                      ? "???"
                      : item.price}
                  </p>
                </span>
              </div>
              <button
                className="shop-button"
                onClick={() => handleBuy(item, isItemLocked(groupIndex))}
                disabled={
                  isItemLocked(groupIndex) || item.price > props.balance
                }
              />
              {/* <button
                className="shop-button"
                onClick={() => handleBuy(item)}
                disabled={item.price > props.balance}
              /> */}
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
};
