// import React from "react";
// import "./HeroContent.css";
// import MarsikWelcome_1 from "../../assets/marsik/hero_1.png";

// const HeroContent = ({ lang, setIsOpen }) => {
//   return (
//     <div className="welcome-panel">
//       <h2 className="gradient-text">{lang.name}</h2>
//       <img src={MarsikWelcome_1} alt="Marsik" className="hero-img"></img>
//       <h3>{lang.hello_title()}</h3>
//       <div>{lang.hello()}</div>

//       <button onClick={() => setIsOpen(false)} className={`continiue`}>
//         {lang.continiue()}
//       </button>
//     </div>
//   );
// };

// export default HeroContent;

import React, { useState, useEffect } from "react";
import "./HeroContent.css";
import MarsikWelcome_1 from "../../assets/marsik/hero_1.png";
import MarsikWelcome_2 from "../../assets/marsik/hero_2.png";
import MarsikWelcome_3 from "../../assets/marsik/hero_3.png";
import MarsikWelcome_4 from "../../assets/marsik/hero_4.png";
import MarsikWelcome_5 from "../../assets/marsik/hero_5.png";

const HeroContent = ({ lang, setIsOpen }) => {
  const [step, setStep] = useState(1);

  const nextStep = () => {
    if (step < 5) {
      setStep(step + 1);
    } else {
      setIsOpen(false);
    }
  };

  const getContent = () => {
    switch (step) {
      case 1:
        return {
          title: lang.hello_title_step1(),
          text: lang.hello_step1(),
          image: MarsikWelcome_1,
        };
      case 2:
        return {
          title: lang.hello_title_step2(),
          text: lang.hello_step2(),
          image: MarsikWelcome_2,
        };
      case 3:
        return {
          title: lang.hello_title_step3(),
          text: lang.hello_step3(),
          image: MarsikWelcome_3,
        };
      case 4:
        return {
          title: lang.hello_title_step4(),
          text: lang.hello_step4(),
          image: MarsikWelcome_4,
        };
      case 5:
        return {
          title: lang.hello_title_step5(),
          text: lang.hello_step5(),
          image: MarsikWelcome_5,
        };
      default:
        return {
          title: lang.hello_title_step1(),
          text: lang.hello_step1(),
          image: MarsikWelcome_1,
        };
    }
  };

  const { title, text, image } = getContent();

  return (
    <div className="welcome-panel">
      <h2 className="gradient-text">{lang.name}</h2>
      <img src={image} alt="Marsik" className="hero-img" />
      <h3>{title}</h3>
      <div className="welcome-h">{text}</div>

      <button onClick={nextStep} className="continue">
        {step < 5 ? lang.continue() : lang.finish()}
      </button>
    </div>
  );
};

export default HeroContent;
