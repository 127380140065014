import React from "react";
import { BottomButton } from "../BottomButton/BottomButton";
import "./BottomPanel.css";

export const BottomPanel = (props) => {
  return (
    <footer className="bottom-panel">
      <BottomButton
        name="shop"
        imgSrc={require("../../assets/menu-item-shop.png")}
        setPage={props.setPage}
        page={props.page}
      />
      <BottomButton
        name="earn"
        imgSrc={require("../../assets/menu-item-crystal.png")}
        setPage={props.setPage}
        page={props.page}
      />
      <BottomButton
        name="wallet"
        imgSrc={require("../../assets/menu-item-wallet-lock.png")}
        setPage={props.setPage}
        page={props.page}
        lock={1}
        openModal={props.openModal}
      />
      <BottomButton
        name="leaderboard"
        imgSrc={require("../../assets/menu-item-leader-lock.png")}
        setPage={props.setPage}
        page={props.page}
        lock={1}
        openModal={props.openModal}
      />
    </footer>
  );
};
