import * as React from "react";
import { useState, useEffect } from "react";
import { Header } from "./Components/Header/Header";
import { BottomPanel } from "./Components/BottomPanel/BottomPanel";
import { get_language } from "./localization";
import { SpaceBG } from "./Components/SpaceBG/SpaceBG";
import Modal from "./Components/Modal/Modal";
import { getPlants, getUser, getPlantings, getSeeds } from "./api";
import Preloader from "./Components/common/Preloader";

function App() {
  const tg = window?.Telegram?.WebApp;
  const [page, setPage] = useState("planets");
  const [language, setLanguage] = useState(1);
  const [lang, setLang] = useState(get_language(language));
  const [energy, setEnergy] = useState(5);
  const [user_id, setUserId] = useState(0);
  const [balance, setBalance] = useState(500);
  const [user, setUser] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [locateBalance, setLocateBalance] = useState(
    balance?.toLocaleString("en-US")
  );
  const [modalComponent, setModalComponent] = useState(null);
  const [plants, setPlants] = useState([]);
  const [plantings, setPlantings] = useState([]);

  const [currentPlanting, setCurrentPlanting] = useState(null);
  const [currentPlanet, setCurrentPlanet] = useState(null);

  const [plantingActive, setPlantingActive] = useState(true);
  const [seeds, setSeeds] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const tg = window?.Telegram?.WebApp;
    if (tg) {
      tg.expand();
      const initDataUnsafe = tg.initDataUnsafe;
      console.log("init data:");
      console.log(initDataUnsafe);
      if (initDataUnsafe !== null) {
        console.log(`initDataUnsafe: ${JSON.stringify(initDataUnsafe)}`);
        setUserId(initDataUnsafe.user.id);
        // setUserId(232383987);
      }
    }
  }, []);

  const maxEnergy = 10;

  const fetchUserData = async () => {
    try {
      const userData = await getUser(user_id);
      setUser(userData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPlants = async () => {
    try {
      const plantsData = await getPlants();
      setPlants(plantsData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPlantings = async () => {
    try {
      const plantingsData = await getPlantings(user_id);
      setPlantings(plantingsData);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchSeeds = async () => {
    try {
      const seedsData = await getSeeds(user_id);
      setSeeds(seedsData);
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   fetchPlants();
  //   fetchUserData();
  //   fetchPlantings();
  //   fetchSeeds();
  // }, [user_id]);

  const fetchData = async () => {
    try {
      await Promise.all([
        fetchPlants(),
        fetchUserData(),
        fetchPlantings(),
        fetchSeeds(),
      ]);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setIsLoading(true);
    }
  };

  useEffect(() => {
    if (user_id) fetchData();
  }, [user_id]);

  // useEffect(() => {
  //   if (user_id) {
  //     fetchData();
  //   }
  // }, [user_id]);

  // useEffect(() => {
  //   fetchPlantings();
  //   fetchUserData();
  // }, [plantingActive]);

  // useEffect(() => {
  //   console.log(`Set new user: ${user}`);
  //   if (user) {
  //     setBalance(user.balance);
  //     setEnergy(user.energy);
  //   }
  // }, [user]);

  // useEffect(() => {
  //   if (balance) setLocateBalance(balance.toLocaleString("en-US"));
  // }, [balance]);

  useEffect(() => {
    console.log(`Set new user: ${JSON.stringify(user)}`);
    if (user) {
      setBalance(user.balance);
      setEnergy(user.energy);
    }
  }, [user]);

  useEffect(() => {
    if (balance != null) {
      setLocateBalance(balance.toLocaleString("en-US"));
    }
  }, [balance]);

  const openModal = (component, planting = null, selectedPlanet) => {
    fetchUserData();
    console.log(planting);
    if (planting) setCurrentPlanting(planting);
    if (selectedPlanet) setCurrentPlanet(selectedPlanet);
    setIsOpenModal(true);
    setModalComponent(component);
  };

  function modify_language() {
    const searchParams = new URLSearchParams(window.location.search);
    let language_temp = searchParams.get("lang");
    if (!language_temp) {
      language_temp = 2;
    } else {
      try {
        language_temp = parseInt(language_temp);
      } catch (error) {
        language_temp = 2;
      }
    }
    setLang(get_language(language_temp));
  }
  // Отримуємо значення параметрів запиту
  useEffect(() => {
    modify_language();
  }, [user_id]);

  useEffect(() => {
    openModal("hero");
  }, []);

  return (
    <main>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <Header
            lang={lang}
            user={user}
            onClickEnergy={() => setPage("earn")}
            setPage={setPage}
            balance={locateBalance}
          />
          <BottomPanel page={page} setPage={setPage} openModal={openModal} />
          <div className="body-page">
            <SpaceBG
              page={page}
              plants={plants}
              seeds={seeds}
              setSeeds={setSeeds}
              setPlantings={setPlantings}
              setUser={setUser}
              user={user}
              user_id={user_id}
              balance={balance}
              lang={lang}
              tg={tg}
              plantings={plantings}
              setPage={setPage}
              setPlantingActive={setPlantingActive}
              plantingActive={plantingActive}
              openModal={openModal}
              setCurrentPlanet={setCurrentPlanet}
            />
            {/* <Hero openModal={openModal} /> */}

            <Modal
              isOpen={isOpenModal}
              setIsOpen={setIsOpenModal}
              component={modalComponent}
              balance={locateBalance}
              setUser={setUser}
              user_id={user_id}
              lang={lang}
              plants={plants}
              plantings={plantings}
              energy={energy}
              setPlantings={setPlantings}
              currentPlanting={currentPlanting}
              setSeeds={setSeeds}
              seeds={seeds}
              setCurrentPlanting={setCurrentPlanting}
              user={user}
              fetchUserData={fetchUserData}
              tg={tg}
              openModal={openModal}
              setPage={setPage}
              currentPlanet={currentPlanet}
              setCurrentPlanet={setCurrentPlanet}
              setPlantingActive={setPlantingActive}
              plantingActive={plantingActive}
            />
          </div>
        </>
      )}
    </main>
  );
}

export default App;

{
  /* <EnergyBar
       energy={energy}
       maxEnergy={maxEnergy}
       /> */
}
{
  /* <Tasks
        onClick={() => openModal('tasks')}
        /> */
}
{
  /* <Market
        onClick={() => openModal('market')}
        />
        <Bank
        onClick={() => openModal('bank')}
        /> */
}
{
  /* <Main
        categorie={categorie}
        lang={lang}
      /> */
}
{
  /* <GardenBeds plantings={plantings} openModal={openModal} lang={lang} /> */
}
