import React, { useState, useEffect, useRef } from "react";
import { WalletItem } from '../WalletItem/WalletItem'
import "./Wallet.css";

export const Wallet = (props) => {
  return (
    <div className="wallet-content">
    <WalletItem 
    token="tether"
    />
    <WalletItem 
    token="btc"
    />
  </div>
  );
};