import React, { useState } from "react";
import "./Modal.css";
import BankContent from "./../Contents/BankContent";
import MarketContent from "./../Contents/MarketContent";
import BalanceContent from "./../Contents/BalanceContent";
import EnergyContent from "./../Contents/EnergyContent";
import HeroContent from "./../Contents/HeroContent";
import PlantingBuildContent from "./../Contents/PlantingBuildContent";
import PlantingInfoContent from "./../Contents/PlantingInfoContent";
import ClosePlanetContent from "./../Contents/ClosedPlanetContent";
import ComingSoonContent from "./../Contents/ComingSoonContent";

const Modal = ({
  isOpen,
  setIsOpen,
  component,
  balance,
  setUser,
  user_id,
  lang,
  plants,
  plantings,
  energy,
  setPlantings,
  currentPlanting,
  seeds,
  setSeeds,
  setCurrentPlanting,
  user,
  fetchUserData,
  tg,
  openModal,
  setPage,
  selectedPlanet,
  currentPlanet,
  setCurrentPlanet,
  planet,
  setPlantingActive,
  plantingActive,
}) => {
  // console.log("Lang:", JSON.stringify(lang.farm()));

  console.log(`selected planet | ${currentPlanet}`);

  return (
    <div
      className={`modal ${isOpen ? "open" : ""} ${
        component === "hero" ? "hero-modal" : ""
      }`}
    >
      <div
        className={`modal-content ${
          component === "hero" ? "modal-content-hero" : ""
        }`}
      >
        {component === "market" && (
          <MarketContent
            balance={balance}
            setUser={setUser}
            user_id={user_id}
            lang={lang}
            plants={plants}
            setSeeds={setSeeds}
            seeds={seeds}
          />
        )}
        {component === "balance" && (
          <BalanceContent
            lang={lang}
            user={user}
            fetchUserData={fetchUserData}
            tg={tg}
          />
        )}
        {component === "bank" && <BankContent lang={lang} />}
        {component === "hero" && (
          <HeroContent lang={lang} setIsOpen={setIsOpen} />
        )}
        {component === "energy" && (
          <EnergyContent
            lang={lang}
            user={user}
            fetchUserData={fetchUserData}
            tg={tg}
            setIsOpen={setIsOpen}
            setPage={setPage}
          />
        )}
        {component === "plantingBuild" && (
          <div>
            <PlantingBuildContent
              lang={lang}
              plantings={plantings}
              setPlantings={setPlantings}
              setUser={setUser}
              openModal={openModal}
              user={user}
              // selectedPlanet={selectedPlanet}
              // planet={planet}
              currentPlanet={currentPlanet}
              setCurrentPlanet={setCurrentPlanet}
            />
          </div>
        )}
        {component === "plantingInfo" && (
          <PlantingInfoContent
            lang={lang}
            currentPlanting={currentPlanting}
            setCurrentPlanting={setCurrentPlanting}
            seeds={seeds}
            setSeeds={setSeeds}
            user_id={user_id}
            setPlantings={setPlantings}
            setUser={setUser}
            setIsOpen={setIsOpen}
            plants={plants}
            energy={energy}
            openModal={openModal}
            setPage={setPage}
            selectedPlanet={selectedPlanet}
            setPlantingActive={setPlantingActive}
            plantingActive={plantingActive}
          />
        )}

        {component === "closedPlanet" && (
          <ClosePlanetContent
            lang={lang}
            user={user}
            fetchUserData={fetchUserData}
            tg={tg}
            planet={planet}
            currentPlanet={currentPlanet}
            setCurrentPlanet={setCurrentPlanet}
          />
        )}

        {component === "hero" ? (
          <></>
        ) : (
          <button onClick={() => setIsOpen(false)} className={`close`}></button>
        )}

        {component === "coming" && (
          <>
            <ComingSoonContent
              lang={lang}
              user={user}
              setIsOpen={setIsOpen}
              setPage={setPage}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
