import React from "react";
import Balance from "../Balance/Balance";
import "./Header.css";
import { energy_limit } from "../../config";

export const Header = (props) => {
  return (
    <header className="header">
      <div className="top-header">
        <div className="left-header">
          {/* <p>{props.user?.first_name}</p> */}
          <Balance
            balance={props.balance}
            onClick={() => props.setPage("wallet")}
            className="balance"
          />
        </div>
        <div className="right-header">
          <div className="level-container">
            <p>{props.user?.level}</p>
          </div>
          <div className="energy-container">
            <p onClick={props.onClickEnergy}>
              {/* ⚡️ */}
              {props.user?.energy}/{energy_limit}
            </p>
          </div>
        </div>
      </div>
    </header>
  );
};
