import React, { useState, useEffect, useRef } from "react";
import "./WalletItem.css";

export const WalletItem = (props) => {
  const token_src = `./${props.token}.png`;
  return (
    <div className="wallet-item-container">
      <img src="./token.png" />
      <span>{"=>"}</span>
      <img src={token_src} />
      <div className="wallet-item-lock-container">
        <img src={require("../../assets/lock-small.png")} alt="lock" />
        {/* <div className="wallet-item-middle-line"></div> */}
      </div>
    </div>
  );
};
