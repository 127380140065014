import React, { useState } from "react";
import { Earn } from "../Earn/Earn";
import { Wallet } from "../Wallet/Wallet";
import { Shop } from "../Shop/Shop";
import "./SpaceBG.css";
import bg from "../../assets/back.png";

import { PlanetSelector } from "../PlanetSelector";

export const SpaceBG = (props) => {
  const [background, setBackground] = useState(bg);

  return (
    <div className="background">
      <img src={background} alt="Background" className="background-img" />
      <div className="content">
        {props.page === "shop" ? (
          <Shop
            plants={props.plants}
            seeds={props.seeds}
            setSeeds={props.setSeeds}
            setUser={props.setUser}
            user={props.user}
            user_id={props.user_id}
            balance={props.balance}
            lang={props.lang}
          />
        ) : props.page === "earn" ? (
          <Earn lang={props.lang} user_id={props.user_id} tg={props.tg} />
        ) : props.page === "wallet" ? (
          <Wallet />
        ) : props.page === "planets" ? (
          <PlanetSelector
            plantings={props.plantings}
            lang={props.lang}
            seeds={props.seeds}
            setPage={props.setPage}
            setSeeds={props.setSeeds}
            setPlantings={props.setPlantings}
            setUser={props.setUser}
            user={props.user}
            user_id={props.user_id}
            fetchPlantings={props.fetchPlantings}
            plantingActive={props.plantingActive}
            setPlantingActive={props.setPlantingActive}
            setBackground={setBackground}
            defaultBg={bg}
            openModal={props.openModal}
            setCurrentPlanet={props.setCurrentPlanet}
          />
        ) : props.page === "leaderboard" ? (
          <div>Leaderboard</div>
        ) : null}
        {props.page !== "planets" && (
          <button
            className="go-to-planets-button"
            onClick={() => props.setPage("planets")}
          >
            {props.lang.back()}
          </button>
        )}
      </div>
    </div>
  );
};
