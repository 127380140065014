// api.js
import axios from "axios";

const api_url = "https://api-test.spacefarm.ink/api/v1";

// const api_url = "https://api.spacefarm.ink/api/v1";
// const api_url = "http://127.0.0.1:8000/api/v1"
export const getUser = async (user_id) => {
  try {
    const response = await axios({
      method: "get",
      url: api_url + `/user/get?user_id=${user_id}`,
      data: JSON.stringify({ user_id: user_id }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const modifyBalance = async (user_id, amount, operation) => {
  try {
    const response = await axios({
      method: "post",
      url: api_url + "/user/balance/modify",
      data: JSON.stringify({
        user_id: user_id,
        amount: amount,
        operation: operation,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const buySeed = async (user_id, plant_id, operation, quantity) => {
  try {
    const response = await axios({
      method: "post",
      url: api_url + "/seed/modify",
      data: JSON.stringify({
        user_id: user_id,
        plant_id: plant_id,
        operation: operation,
        quantity: quantity,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const unlockSeedApi = async (user_id, plant_id) => {
  try {
    const response = await axios({
      method: "post",
      url: api_url + "/seed/unlock",
      data: JSON.stringify({
        user_id: user_id,
        plant_id: plant_id,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getPlants = async () => {
  try {
    const response = await axios({
      method: "post",
      url: api_url + "/plants/get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getPlantings = async (user_id) => {
  try {
    const response = await axios({
      method: "post",
      url: api_url + "/plantings/get",
      data: JSON.stringify({ user_id: user_id }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const wateringPlantings = async (planting_id) => {
  try {
    const response = await axios({
      method: "post",
      url: api_url + "/planting/watering",
      data: JSON.stringify({ planting_id: planting_id }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const harvestPlantings = async (planting_id) => {
  try {
    const response = await axios({
      method: "post",
      url: api_url + "/planting/harvest",
      data: JSON.stringify({ planting_id: planting_id }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const pasteSeeds = async (user_id, plant_id, plantation_id) => {
  try {
    const response = await axios({
      method: "post",
      url: api_url + "/seeds/paste",
      data: JSON.stringify({
        user_id: user_id,
        plant_id: plant_id,
        plantation_id: plantation_id,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSeeds = async (user_id) => {
  try {
    const response = await axios({
      method: "post",
      url: api_url + "/seeds/get",
      data: JSON.stringify({ user_id: user_id }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const checkSubscribe = async (user_id) => {
  try {
    const response = await axios({
      method: "post",
      url: api_url + "/user/subscribe",
      data: JSON.stringify({ user_id: user_id }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// export const buildPlantings = async (user_id, price, energy) => {
//   try {
//     const response = await axios({
//       method: "post",
//       url: api_url + "/plantings/build",
//       data: JSON.stringify({ user_id: user_id, price: price, energy: energy }),
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// };

export const buildPlantings = async (user_id, planet_id) => {
  try {
    const response = await axios({
      method: "post",
      url: api_url + "/planting/build",
      data: JSON.stringify({ user_id: user_id, planet_id: planet_id }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const BuildPriceGet = async (user_id, planet_id) => {
  try {
    const response = await axios({
      method: "post",
      url: api_url + "/planting/build/calculate",
      data: JSON.stringify({ user_id: user_id, planet_id: planet_id }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const InviteGet = async (user_id) => {
  try {
    const response = await axios({
      method: "get",
      url: api_url + `/invite/get?user_id=${user_id}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
