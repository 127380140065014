class EnLanguage {
  constructor() {
    this.name = "Space Farm";
    this.token = "SFT";
    this.language_name = "English";
  }

  farm() {
    return "Farm";
  }

  buy() {
    return "Buy";
  }

  close() {
    return "Close";
  }

  total_cost() {
    return "Total Cost";
  }

  market() {
    return "Market";
  }

  plants(id) {
    if (id === 1) return "Potato";
    else if (id === 2) return "Onion";
    else if (id === 3) return "Cabbage";
    else return "Unknown";
  }

  buildPlantingTitle(lenght) {
    return `Plantation №${lenght + 1}`;
  }

  buildPlantingDesc() {
    return `You can build a new plantation and plant seeds in it.`;
  }

  buildPlantingDesc2() {
    return `After harvesting - you get ${this.token} right away`;
  }

  build() {
    return "Build";
  }

  select_seed() {
    return "Select seeds for planting";
  }

  planting_no_seed() {
    return "Grow";
  }

  planting() {
    return "Harvest";
  }

  harvest() {
    return "Harvest";
  }

  watering() {
    return "Watering";
  }

  seed() {
    return "Seed";
  }

  name_by_id(id) {
    if (id === 1) return "Electro Spud";
    else if (id === 2) return "Orbit Carrot";
    else if (id === 3) return "PopCorn Star";
    else if (id === 4) return "Eclipse Flower ";
    else if (id === 5) return "Liquid Cabbage";
    else if (id === 6) return "Moon Blueberry";
    else return "Electro Spud";
  }

  en_name_by_id(id) {
    if (id === 1) return "potato";
    else if (id === 2) return "carrot";
    else if (id === 3) return "popcorn";
    else if (id === 4) return "flower";
    else if (id === 5) return "gabbage";
    else if (id === 6) return "bluebery";
    else return "potato";
  }

  earn() {
    return "Earn";
  }

  cost() {
    return "Cost";
  }

  energy() {
    return "Energy";
  }

  get_energy() {
    return "Get Energy";
  }

  energy_not_enough() {
    return "You dont have enough energy";
  }

  coming_soon() {
    return "Coming soon";
  }

  next_energy() {
    return "Next energy:";
  }

  subscribe_community() {
    return "Join channel";
  }

  invite_frends() {
    return "Invite friends";
  }

  invite_msg() {
    return "Become my friend and earn with me";
  }

  balance() {
    return "Your balance";
  }

  balance_enough() {
    return "You don't have enough coins";
  }

  exchange() {
    return "Exchange";
  }

  hello_step1() {
    return "Discover many planets and build your farm on them to earn coins!";
  }

  hello_step2() {
    return "Open new beds, plant crops and collect rewards when the fruit is ready !";
  }

  hello_step3() {
    return "Use the shop to unlock and buy new crops. You can upgrade a plant to increase its profit !";
  }

  hello_step4() {
    return "Grow crops, speed up growth, and unlock plantations. Energy replenishes, and its limit can be increased by inviting a friend!";
  }

  hello_step5() {
    return "Invite friends and complete other  quests to earn more coins and bonuses!";
  }

  hello_title_step1() {
    return "Galaxy Travel";
  }

  hello_title_step2() {
    return "Space Farm";
  }

  hello_title_step3() {
    return "Plant Shop";
  }

  hello_title_step4() {
    return "Use Energy Wisely";
  }

  hello_title_step5() {
    return "Quests";
  }

  continue() {
    return "Continue";
  }

  finish() {
    return "Finish";
  }

  invite_lefts(count) {
    return ` (${count}) left`;
  }

  start() {
    return "Start";
  }

  claim() {
    return "Claim";
  }

  done() {
    return "Done";
  }

  back() {
    return "Back";
  }

  closed_planets() {
    return "You need more invite crystals to open this planet";
  }
}

class RuLanguage extends EnLanguage {
  constructor() {
    super();
    this.name = "Space Farm";
    this.token = "SFT";
    this.language_name = "Русский";
  }

  farm() {
    return "Ферма";
  }

  buy() {
    return "Купить";
  }

  close() {
    return "Закрыть";
  }

  total_cost() {
    return "Общяя сумаа";
  }

  market() {
    return "Магазин";
  }

  plants(id) {
    if (id === 1) return "Картошка";
    else if (id === 2) return "Лук";
    else if (id === 3) return "Капуста";
    else return "Неизвестно";
  }

  buildPlantingTitle(lenght) {
    return `Плантация №${lenght + 1}`;
  }

  buildPlantingDesc() {
    return `Вы можете построить новую плантацию и посадить в ней семена.`;
  }

  buildPlantingDesc2() {
    return `После сбора урожая – вы получаете сразу ${this.token}`;
  }

  build() {
    return "Построить";
  }

  select_seed() {
    return "Выбор семян для посадки";
  }

  planting_no_seed() {
    return "Вырастить";
  }

  planting() {
    return "Посадка";
  }

  harvest() {
    return "Собрать";
  }

  watering() {
    return "Полить";
  }

  seed() {
    return "Семена";
  }

  name_by_id(id) {
    if (id === 1) return "Електро Картофель";
    else if (id === 2) return "Орбитальная Морковь";
    else if (id === 3) return "Звездная кукуруза";
    else if (id === 4) return "Лунная голубика";
    else if (id === 5) return "Цветок затмения";
    else if (id === 6) return "Жидкая капуста";
    else return "Електро Картофель";
  }

  earn() {
    return "Доход";
  }

  cost() {
    return "Стоимость";
  }

  energy() {
    return "Энергия";
  }

  get_energy() {
    return "Получить энергию";
  }

  energy_not_enough() {
    return "У тебя не достаточно энергии";
  }

  coming_soon() {
    return "Скоро станет доступно";
  }

  next_energy() {
    return "Следующая энергия:";
  }

  subscribe_community() {
    return "Подписаться";
  }

  invite_frends() {
    return "Пригласить друзей";
  }

  invite_msg() {
    return "Стань моим другом и зарабатывай со мной";
  }

  balance() {
    return "Ваш баланс";
  }

  balance_enough() {
    return "У тебя не достаточно монет";
  }

  exchange() {
    return "Обменник";
  }

  hello_step1() {
    return "Откройте для себя множество планет и постройте на них свою ферму, чтобы заработать монеты!";
  }

  hello_step2() {
    return "Открывайте новые грядки, сажайте культуры и собирайте награды, когда плоды будут готовы!";
  }

  hello_step3() {
    return "Используйте магазин, чтобы разблокировать и купить новые культуры. Вы можете модернизировать растение, чтобы увеличить его прибыль!";
  }

  hello_step4() {
    return "Выращивайте культуры, ускоряйте рост и открывайте плантации. Энергия восполняется, а ее лимит можно увеличить, пригласив друга!";
  }

  hello_step5() {
    return "Приглашайте друзей и выполняйте другие задания, чтобы заработать больше монет и бонусов!";
  }

  hello_title_step1() {
    return "Галактическое путешествие";
  }

  hello_title_step2() {
    return "Космическая ферма";
  }

  hello_title_step3() {
    return "Магазин растений";
  }

  hello_title_step4() {
    return "Используйте энергию с умом";
  }

  hello_title_step5() {
    return "Квесты";
  }

  continue() {
    return "Продолжить";
  }

  finish() {
    return "Закончить";
  }

  invite_lefts(count) {
    return ` (${count}) осталось`;
  }

  back() {
    return "Назад";
  }

  closed_planets() {
    return "Вам нужно больше пригласительных кристаллов, чтобы открыть эту планету";
  }
}

export function get_language(language = 1) {
  if (language === 1) {
    return new RuLanguage();
  } else if (language === 2) {
    return new EnLanguage();
  } else {
    return new RuLanguage();
  }
}
