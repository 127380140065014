import React, { useEffect, useState } from "react";
import "./PlantingBuildContent.css";
import { build_coef, basic_price, energy_price } from "../../config";
import { buildPlantings, BuildPriceGet } from "../../api";

const PlantingBuildContent = ({
  lang,
  plantings,
  setPlantings,
  setUser,
  openModal,
  user,
  selectedPlanet,
  currentPlanet,
  setCurrentPlanet,
}) => {
  // const [build_price, setBuildPrice] = useState(basic_price);
  const [buildPrice, setBuildPrice] = useState(basic_price);

  // useEffect(() => {
  //   let price = basic_price;
  //   for (let i = 0; i < plantings.length; i++) {
  //     console.log(plantings, i);
  //     price = Math.round(price * build_coef);
  //   }
  //   setBuildPrice(price);
  // }, [plantings]);

  // const buildPlantation = async () => {
  //   try {
  //     const data = await buildPlantings(
  //       user.id,
  //       user.planet

  //       // build_price,
  //       // energy_price.build
  //     );
  //     if (data.length !== 0) {
  //       if (data?.plantings) setPlantings(data.plantings);
  //       if (data?.user) setUser(data.user);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getBuildPrice = async () => {
    try {
      const data = await BuildPriceGet(user.id, currentPlanet);
      if (data && data.data) {
        console.log(`get price ${(data, data.data)}`);
        setBuildPrice(data.data);
      }
    } catch (error) {
      console.error("Failed to get price build plantation:", error);
    }
  };

  useEffect(() => {
    if (currentPlanet) {
      getBuildPrice();
    }
    console.log(`get price ${currentPlanet}`);
  }, [currentPlanet, user.id]);

  const buildPlantation = async () => {
    try {
      const data = await buildPlantings(user.id, currentPlanet);
      if (data && data.data) {
        if (data.data.plantings) setPlantings(data.data.plantings);
        if (data.data.user) setUser(data.data.user);
      }
    } catch (error) {
      console.error("Failed to build plantation:", error);
    }
  };

  return (
    <div>
      <div className="modal-heading">
        <h2 className="rotated-text">{lang.build()}</h2>
      </div>

      <div className="build-button-container">
        <div className="build">
          {/* <p className="build-text">{lang.build()}</p> */}
          <div
            className="price-info"
            style={{ color: buildPrice > user.balance ? "red" : "white" }}
          >
            {buildPrice}
            {/* <img src="./token.png" alt="Token" className="token-image-build" /> */}
          </div>
          <div
            className="price-info"
            style={{ marginTop: "40px", marginLeft: "10px" }}
          >
            <span
              style={{
                color: energy_price.build > user.energy ? "red" : "white",
              }}
            >
              {energy_price.build}
            </span>
          </div>
          <button
            className="accept"
            onClick={() =>
              buildPrice > user.balance
                ? openModal("balance")
                : energy_price.build > user.energy
                ? openModal("energy")
                : buildPlantation()
            }
          ></button>
        </div>
      </div>

      <div className="build-desc">
        {lang.buildPlantingDesc()} <br /> {lang.buildPlantingDesc2()}
        <br />
        <br />
      </div>
    </div>
  );
};

export default PlantingBuildContent;
