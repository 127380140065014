import React from "react";
import "./Balance.css";

const Balance = ({ balance, onClick }) => {
  return (
    <div className="balances" onClick={onClick}>
      {/* <img src="./token.png" alt="Token" className="token-image" /> */}
      <p>{balance}</p>
    </div>
  );
};
export default Balance;
