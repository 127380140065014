import React, { useState, useEffect } from "react";
import "./BankContent.css";
import lock from "../../assets/lock.png";

const BankContent = ({ lang }) => {
  return (
    <>
      <h2>{lang.exchange()}</h2>
      <button
        className="button"
        style={{ position: "relative", height: "50px" }}
      >
        <div id="lock-overlay" style={{ opacity: 0.5 }}>
          <div className="lock-content">
            <img src={lock} alt="lock" />
          </div>
        </div>

        <p className="exchange-p">
          <img src="./token.png" alt="Token" className="img-in-text" />
          {" => "}
          <img src="./tether.png" alt="Token" className="img-in-text" />
        </p>
      </button>
      <button
        className="button"
        style={{ position: "relative", height: "50px" }}
      >
        <div id="lock-overlay" style={{ opacity: 0.5 }}>
          <div className="lock-content">
            <img src={lock} alt="lock" />
          </div>
        </div>

        <p className="exchange-p">
          <img src="./token.png" alt="Token" className="img-in-text" />
          {" => "}
          <img src="./btc.png" alt="Token" className="img-in-text" />
        </p>
      </button>
    </>
  );
};

export default BankContent;
