// import React, { useState, useEffect, useRef } from "react";
// import "./Earn.css";
// import { checkSubscribe } from "../../api";
// import { energy_limit, earn_for_task, channel_link } from "../../config";
// import { TaskItem } from "../TaskItem/TaskItem";

// export const Earn = (props) => {
//   const frends_task = {
//     name: "Invite frends",
//     social: "frends",
//     earn: earn_for_task,
//     energy: energy_limit,
//     status: 0,
//   };

//   const get_info_subscribe = async () => {
//     const data = await checkSubscribe(props.user_id);
//     return data;
//   };

//   const join_channel_task = {
//     name: "Join channel",
//     social: "tg",
//     earn: earn_for_task,
//     energy: energy_limit,
//     status: get_info_subscribe() ? 1 : 0,
//     link: channel_link,
//   };
//   return (
//     <div className="earn-content">
//       <TaskItem
//         task={frends_task}
//         lang={props.lang}
//         user_id={props.user_id}
//         tg={props.tg}
//       />
//       <TaskItem
//         task={join_channel_task}
//         lang={props.lang}
//         user_id={props.user_id}
//         tg={props.tg}
//       />
//     </div>
//   );
// };

import React, { useState, useEffect } from "react";
import "./Earn.css";
import { checkSubscribe } from "../../api";
import { energy_limit, earn_for_task, channel_link } from "../../config";
import { TaskItem } from "../TaskItem/TaskItem";

export const Earn = (props) => {
  const [subscribeStatus, setSubscribeStatus] = useState(0); // 0 - неизвестно, 1 - подписан, 2 - не подписан

  useEffect(() => {
    const fetchSubscribeStatus = async () => {
      try {
        const isSubscribed = await checkSubscribe(props.user_id);
        setSubscribeStatus(isSubscribed ? 1 : 2);
      } catch (error) {
        console.error("Error checking subscription:", error);
        setSubscribeStatus(2); // Предполагаем, что не подписан в случае ошибки
      }
    };

    fetchSubscribeStatus();
  }, [props.user_id]);

  const frends_task = {
    name: "Invite frends",
    social: "frends",
    earn: earn_for_task,
    energy: energy_limit,
    status: 0,
  };

  const join_channel_task = {
    name: props.lang.subscribe_community(),
    social: "tg",
    earn: earn_for_task,
    energy: energy_limit,
    status: subscribeStatus,
    link: channel_link,
  };

  return (
    <div className="earn-content">
      <TaskItem
        task={frends_task}
        lang={props.lang}
        user_id={props.user_id}
        tg={props.tg}
      />
      <TaskItem
        task={join_channel_task}
        lang={props.lang}
        user_id={props.user_id}
        tg={props.tg}
      />
    </div>
  );
};
